import { useEffect, useState } from "react";
import { Header } from "../components/Header/Header";
import Container from "../components/ui/container/Container";
import Box from "../components/ui/box/Box";
import { IFindAllMeetingsResponse } from "../types/IFindAllMeetingsResponse";
import { FindAllMeetings } from "../services/api/FindMeetings";
import { MeetingCanceled } from "../components/Meetings/MeetingCanceled";
import { MeetingScheduled } from "../components/Meetings/MeetingScheduled";
import { MeetingEnded } from "../components/Meetings/MeetingEnded";
import { MeetingInProgress } from "../components/Meetings/MeetingInProgress";
import IframeLoading from "../components/Player/IframeLoading";
import Whatsapp from "../components/Whatsapp/Whatsapp";
import { useTranslation } from 'react-i18next';

export const Meetings = () => {

  const [meetings, setMeetings] = useState<IFindAllMeetingsResponse[]>();
  const { t } = useTranslation();

  useEffect(() => {
    FindAllMeetings.execute().then((response) => {
      setMeetings(response)
    })
  },[]);

  return (
    <div>
      <Whatsapp />
      <Header />
      <main>
        <Container>
          <div className="flex flex-wrap md:pt-20 lg:-mx-6 py-4">
            <div className="flex w-full justify-center">
              <Box className="w-full md:w-2/3 divide-y divide-gray-100">
                <div className="flex flex-col gap-2">
                  <h1 className="font-bold py-2 text-xl">{t('pages.Meetings.title')}</h1>
                 
                  <div className="py-2">
                    <IframeLoading src="ee9718fa-22fe-4012-9286-14cb5bf5ff24" isLoading={false} />
                  </div>

                  <div className="bg-gray-50 p-4 gap-4">
                    <h3 className="font-bold text-lg text-teal-500">
                    {t('pages.Meetings.information.title')}
                    </h3>
                    <p className="text-justify text-teal-500">
                    {t('pages.Meetings.information.description')}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2 py-6">
                  <h3 className="font-bold text-lg">{t('pages.Meetings.downloads.title')}</h3>
                  <p className="text-justify">
                  {t('pages.Meetings.downloads.description')}
                  <a className="text-teal-500 font-bold" href="https://zoom.us/pt/download">
                    {t('pages.Meetings.downloads.link')}
                  </a>
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <h3 className="font-bold text-lg py-4">{t('pages.Meetings.schedule')}</h3>
                  <div className="divide-y divide-gray-100 flex flex-col gap-2">
                    {meetings && meetings.map((meeting) => (
                      <div key={meeting.id}>
                        {meeting.status === 'canceled' &&  <MeetingCanceled {...meeting} />}
                        {meeting.status === 'scheduled' &&  <MeetingScheduled {...meeting} />}
                        {meeting.status === 'ended' &&  <MeetingEnded {...meeting} />}
                        {meeting.status === 'inProgress' &&  <MeetingInProgress {...meeting} />}
                      </div>
                    ))}    
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </Container>
      </main>
    </div>
  )
}