import React from "react";
import * as Primitives from "@radix-ui/react-scroll-area";

export interface ScrollAreaProps {
  children: React.ReactNode;
}

export const ScrollArea: React.FC<ScrollAreaProps> = ({ children }) => {
  return (
    <Primitives.Root className="h-full w-full overflow-hidden">
      <Primitives.Viewport className="h-full w-full rounded">
        {children}
      </Primitives.Viewport>
      <Primitives.Scrollbar
        className="bg-blackA6 hover:bg-blackA8 flex touch-none select-none p-0.5 transition-colors duration-[160ms] ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
        orientation="vertical"
      >
        <Primitives.Thumb className="relative flex-1 rounded-[10px] bg-green-200 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
      </Primitives.Scrollbar>
    </Primitives.Root>
  );
};
