import { IFindAllCommentsService } from "../../types/IFindAllCommentsService";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  get: (url: string) =>
  ApiInstance.get<IFindAllCommentsService>(url).then(ResponseBody),
};

export const FindAllCommentsService = {
  execute: (status: string): Promise<IFindAllCommentsService> =>
  RequestService.get(`/comments?status=${status}`)
};


