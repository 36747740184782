import { IRegisterParticipantRequest } from "../../types/IRegisterParticipantRequest";
import { IRegisterParticipantResponse } from "../../types/IRegisterParticipantResponse";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  post: (url: string, body: IRegisterParticipantRequest) =>
    ApiInstance.post(url, body).then(ResponseBody),
};

export const RegisterParticipantService = {
  execute: (request: IRegisterParticipantRequest): Promise<IRegisterParticipantResponse> =>
  RequestService.post('/meetings/register', request)
};
