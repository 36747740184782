import React from "react";
import { tv, VariantProps } from "tailwind-variants";
import { cn } from "../../../utils/index";

const buttonVariants = tv({
  base: "inline-flex gap-2 items-center justify-center focus:outline-none transition ease-in-out duration-150 enabled:hover:bg-opacity-90 disabled:opacity-75 cursor-pointer",
  variants: {
    variant: {
      primary: "bg-teal-400 text-white",
      secondary: "bg-gray-200 text-gray-900",
      tertiary: "text-teal-500",
    },
    size: {
      sm: "px-4 py-2 text-sm rounded-lg font-medium",
      md: "px-6 py-4 text-sm rounded-lg font-medium",
      lg: "px-8 py-6 text-sm rounded-lg font-medium",
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => (
    <button
      ref={ref}
      className={cn(buttonVariants({ variant, size }), className)}
      {...props}
    />
  )
);

export default Button;
