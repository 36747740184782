import React from "react";
import * as AccordionPrimitives from "@radix-ui/react-accordion";
import { cn } from "../../../utils";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitives.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitives.Trigger>
>(({ className, ...props }, ref) => {
  return (
    <AccordionPrimitives.Trigger
      ref={ref}
      className={cn("group flex flex-1 items-center gap-2", className)}
      {...props}
    />
  );
});

export default AccordionTrigger;
