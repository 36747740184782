import { ICourse } from "../../types/ICourse";
import { ScrollArea } from "../ui/ScrollArea/ScrollArea";
import Accordion from "../ui/accordion";
import List from "../ui/list";
import Text from "../ui/text/Text";
import { LuChevronLeft } from "react-icons/lu";
import { ClassroomNavigate } from "./ClassroomNavigate/ClassroomNavigate";
import { useTranslation } from 'react-i18next';

export interface ClassroomNavigationProps {
  course: ICourse;
}

export const ClassroomNavigation: React.FC<ClassroomNavigationProps> = ({
  course,
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  return (
    <div className="fixed bottom-0 md:bottom-5 left-0 md:left-5 top-0 md:top-5 w-full md:w-96 lg:block z-50">
      <div className="relative h-full bg-white">
        <ScrollArea>
          <div className="p-4">
            <a
              href={`/${locale}/course/${course.id}`}
              className="ga-4 inline-flex items-center text-sm font-medium text-gray-600"
            >
              <LuChevronLeft className="w-4" />
              {t('pages.Lesson.back')}
            </a>
          </div>

          <div className="px-4 lg:mt-7">
            <h2 className="px-2 text-lg font-bold leading-tight text-gray-900">
              {course?.name}
            </h2>
          </div>
          <div className="flex flex-col gap-2 p-4">
            {course.modules &&
              course.modules.map((module) => (
                <Accordion.Root
                  accessKey={`module-${module.id}`}
                  type="single"
                  collapsible
                  key={module.id}
                >
                  <Accordion.Item value="1">
                    <Accordion.Header>
                      <Accordion.Trigger>
                        <Accordion.TriggerIcon />
                        <Text>{module.name}</Text>
                      </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Content>
                      <List.Root className="divide-y divide-gray-100">
                        {module.lessons &&
                          module.lessons.map((lesson) => (
                            <List.Item
                              className="flex items-center gap-2 px-5 py-4"
                              key={lesson.id}
                            >
                              <ClassroomNavigate toLesson={lesson} />
                            </List.Item>
                          ))}
                      </List.Root>
                    </Accordion.Content>
                  </Accordion.Item>
                </Accordion.Root>
              ))}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};
