import React from "react";
import * as AccordionPrimitives from "@radix-ui/react-accordion";
import { cn } from "../../../utils";

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitives.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitives.Item>
>(({ className, ...props }, ref) => {
  return (
    <AccordionPrimitives.Item
      ref={ref}
      className={cn("flex flex-col", className)}
      {...props}
    />
  );
});

export default AccordionItem;
