import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import brTranslation from './src/locales/br.json';
import esTranslation from './src/locales/es.json';

const resources = {
  br: {
    ...brTranslation,
  },
  es: {
    ...esTranslation,
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    //lng: "br",
    resources,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['path', 'navigator'],
      lookupFromPathIndex: 0,
    },
    fallbackLng: 'br',

  }
  );

  export default i18n;