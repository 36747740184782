import { useState } from "react";
import { useAuth } from "../../../hooks/Auth";
import { Comments } from "../../../services/api/CommentsRequests";
import { IComment } from "../../../types/IComment";
import Avatar from "../../ui/Avatar/Avatar";
import { ClassroomComments } from "../ClassroomComments";
import { ClassroomCommentEdit } from "../ClassroomCommentEdit/ClassroomCommentEdit";
import { parseISO, formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Button from "../../ui/button/Button";
import { FindCommentsByLessonService } from "../../../services/api/FindCommentsByLessonService";
import { useClassRoom } from "../../../hooks/ClassRoom";
import { useComments } from "../../../hooks/Comments";
import { ClassroomReply } from "../ClassroomReply/ClassroomReply";
import { useTranslation } from 'react-i18next';

export interface ClassroomCommentProps {
  comment: IComment;
}

export const ClassroomComment: React.FC<ClassroomCommentProps> = ({comment}) => {
  const { user } = useAuth();
  const [edit, setEdit] = useState<boolean>(false);
  const [reply, setReply] = useState<boolean>(false)
  const { lesson } = useClassRoom();
  const { setComments } = useComments();
  const { t } = useTranslation();

  const handleDeleteComment = () => {
    comment &&
      Comments.deleteComment(comment.id).then(() => {
        FindCommentsByLessonService.execute(lesson.id).then((comments) => {
          setComments(comments)
        })
      });
  };

  return (
    <div className="flex gap-4 py-4">
      <div>
        <Avatar
          name={comment.user.full_name}
          src={comment.user.profile_image_url}
        />
      </div>
      <div className="flex-1">
        <div className="flex gap-2 items-center">
          <h4 className="text-lg font-bold group-hover:text-teal-400">
            {comment.user.full_name}
          </h4>
          <span className="text-gray-600 text-sm"> - {formatDistance(parseISO(comment.updated_at.toString()), Date.now(), {addSuffix: true, locale: ptBR})}</span>
        </div>
        {!edit && (<p className="mt-1 break-all whitespace-pre-line">{comment.content}</p>)}
        <div className="flex">
        {!edit && !reply && (
          <div>
            <Button variant="tertiary" size="sm" onClick={() => setReply(true)}>{t('pages.Lesson.reply')}</Button>
            {user.id === comment.user.id && (
              <>
                <Button variant="tertiary" size="sm" onClick={() => setEdit(true)} >{t('pages.Lesson.edit')}</Button>
                <Button variant="tertiary" size="sm" onClick={() => handleDeleteComment()}>{t('pages.Lesson.delete')}</Button>
              </>
            )}
          </div>
        ) }
        </div>
        {edit && <ClassroomCommentEdit comment={comment} onClose={() => setEdit(false)} />}
        {reply && <ClassroomReply comment={comment} onClose={() => setReply(false)} />}
        {comment?.replies && <ClassroomComments comments={comment.replies} />}
      </div>
    </div>
  )
}