import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  get: (url: string) =>
  ApiInstance.get<{notifications: number}>(url).then(ResponseBody),
};

export const CountByUserNotificationsService = {
  execute: (): Promise<{notifications: number}> =>
  RequestService.get('/notifications/count')
};
