// import reactLogo from './assets/react.svg'
// import viteLogo from '/vite.svg'
// import './App.css'

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Account } from "./pages/Account";
import { CoursePage } from "./pages/Course";
import { CoursesPage } from "./pages/Courses";
import { Lesson } from "./pages/Lesson";
import { NotFound } from "./pages/NotFound";
import { SignIn } from "./pages/SignIn";
import { AuthProvider } from "./contexts/AuthContext";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { Toaster } from "./components/ui/Toaster/Toaster";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Comments } from "./pages/Comments";
import { ResetPassword } from "./pages/ResetPassword";
import { Register } from "./pages/Register";
import { Notifications } from "./pages/Notifications";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { Meetings } from "./pages/Meetings";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/:locale/" element={<SignIn />} />
          <Route path="/:locale/forgotpassword" element={<ForgotPassword />} />
          <Route path="/:locale/resetpassword" element={<ResetPassword />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/:locale/notifications"
            element={
              <PrivateRoute>
                <Notifications />
              </PrivateRoute>
            }
          />
          <Route
            path="/:locale/courses"
            element={
              <PrivateRoute>
                <CoursesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/:locale/course/:id"
            element={
              <PrivateRoute>
                <CoursePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/:locale/lesson/:course_id/:lesson_id"
            element={
              <PrivateRoute>
                <Lesson />
              </PrivateRoute>
            }
          />
          <Route
            path="/:locale/account"
            element={
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            }
          />
          <Route
            path="/:locale/comments/:status"
            element={
              <PrivateRoute>
                <Comments />
              </PrivateRoute>
            }
          />
          <Route
            path="/:locale/meetings"
            element={
              <PrivateRoute>
                <Meetings />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Toaster />
        <ToastContainer position="top-right" autoClose={5000} closeOnClick pauseOnFocusLoss pauseOnHover />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
