import React from "react";
import * as AccordionPrimitives from "@radix-ui/react-accordion";
import { cn } from "../../../utils";

const AccordionHeader = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitives.Header>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitives.Header>
>(({ className, ...props }, ref) => {
  return (
    <AccordionPrimitives.Header
      ref={ref}
      className={cn("flex p-6", className)}
      {...props}
    />
  );
});

export default AccordionHeader;
