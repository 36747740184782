import Textarea from "../../ui/Textarea/Textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import Button from "../../ui/button/Button";
import Spinner from  '../../ui/Spinner/Spinner'
import { Comments } from "../../../services/api/CommentsRequests";
import { IComment } from "../../../types/IComment";
import { useComments } from "../../../hooks/Comments";
import { FindCommentsByLessonService } from "../../../services/api/FindCommentsByLessonService";
import { useTranslation } from 'react-i18next';

const formSchema = z.object({
  content: z.string(),
});

type FormSchema = z.infer<typeof formSchema>;

export interface ClassroomReplyProps {
  comment: IComment;
  onClose: () => void;
}

export const ClassroomReply: React.FC<ClassroomReplyProps> = ({comment, onClose}) => {
  const {setComments} = useComments();
  const { register, handleSubmit, formState: { errors, isSubmitting }, } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const { t } = useTranslation();
  
  const onSubmit = async (formValues: FormSchema) => {
    Comments.createReply({
      ...formValues,
      reply_to_id: comment.id,
      lesson_id: comment.lesson_id,
    }).then(() => {
      onClose();
      FindCommentsByLessonService.execute(comment.lesson_id).then((comments) => {
        setComments(comments)
      })
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Textarea
        rows={4}
        {...register("content", {
          required: true,
        })}
        />
      {errors.content && (<p>{errors.content.message}</p>)}
      <div className="flex justify-end gap-4 py-2">
        <Button type="submit" className="w-full md:w-auto" variant="secondary" onClick={() => onClose()}>
        {t('pages.Lesson.cancel')}
        </Button>
        <Button type="submit" className="w-full md:w-auto">
          {isSubmitting && <Spinner size="sm" variant="secondary" />}
          <span>{t('pages.Lesson.send')}</span>
        </Button>
      </div>
      </form>
    </div>
  )
}