import React from "react";
import { LuChevronDown } from "react-icons/lu";
import { cn } from "../../../utils";

const AccordionTriggerIcon = React.forwardRef<
  React.ElementRef<typeof LuChevronDown>,
  React.ComponentPropsWithoutRef<typeof LuChevronDown>
>(({ className, ...props }, ref) => {
  return (
    <LuChevronDown
      ref={ref}
      className={cn(
        "transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180",
        className
      )}
      {...props}
    />
  );
});

export default AccordionTriggerIcon;
