import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  put: (url: string) => ApiInstance.put(url).then(ResponseBody),
};

export const WatchedService = {
  execute: (lesson_id: number) => RequestService.put(`/watched/${lesson_id}`),
};
