import React, { createContext, useState } from "react";
import { ILesson } from "../types/ILesson";

export interface IClassRoomProviderValue {
  lesson: ILesson;
  setLesson: (lesson: ILesson) => void;
}

export interface IClassRoomProvider {
  currentLesson?: ILesson;
  children: React.ReactNode;
}

export const ClassRoomContext = createContext<IClassRoomProviderValue>(
  {} as IClassRoomProviderValue
);

export const ClassRoomProvider: React.FC<IClassRoomProvider> = ({
  children,
  currentLesson,
}) => {
  const [lesson, setLesson] = useState<ILesson>(
    currentLesson || ({} as ILesson)
  );

  return (
    <ClassRoomContext.Provider
      value={{
        lesson,
        setLesson,
      }}
    >
      {children}
    </ClassRoomContext.Provider>
  );
};
