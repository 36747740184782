import { ICourse } from "../../types/ICourse";
import { ICoursePaginate } from "../../types/ICoursePaginate";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const CoursesRequests = {
  FindAll: (url: string) =>
    ApiInstance.get<ICoursePaginate>(url).then(ResponseBody),
  FindById: (url: string) =>
    ApiInstance.get<ICoursePaginate>(url).then(ResponseBody),
};

export const Courses = {
  FindAll: (): Promise<ICoursePaginate> => CoursesRequests.FindAll("/courses"),
  FindById: (id: number): Promise<ICourse> =>
    CoursesRequests.FindById(`/courses/${id}`),
};
