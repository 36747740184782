import { parseISO } from 'date-fns'; 
import { format } from 'date-fns-tz';
import { FaCalendarTimes } from "react-icons/fa";
import { IFindAllMeetingsResponse } from "../../types/IFindAllMeetingsResponse";
import { useTranslation } from 'react-i18next';

export const MeetingCanceled: React.FC<IFindAllMeetingsResponse> = ({
  title,
  meeting_date
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col md:flex-row justify-between items-center gap-6 py-2">
      <div className="flex flex-col md:flex-row md:items-center gap-4">
        <div className='flex items-center gap-2'>
          <FaCalendarTimes className="text-red-700" />
          <span className="bg-red-100 text-red-800 px-4 py-1 rounded-full">
          {format(parseISO(`${meeting_date}`), 'dd/MM/yyyy', {timeZone: 'America/Sao_Paulo'})}
          </span>
        </div>
        <h4 className="font-medium">{title}l</h4>
      </div>
      <span className="text-red-700">{t('pages.Meetings.canceled')}</span>
    </div>
  )
}
