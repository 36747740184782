import { IFindAllMeetingsResponse } from "../../types/IFindAllMeetingsResponse";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  get: (url: string) =>
  ApiInstance.get<IFindAllMeetingsResponse[]>(url).then(ResponseBody),
};

export const FindAllMeetings = {
  execute: (): Promise<IFindAllMeetingsResponse[]> =>
  RequestService.get('/meetings')
};
