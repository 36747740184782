import AccordionContent from "./AccordionContent";
import AccordionHeader from "./AccordionHeader";
import AccordionItem from "./AccordionItem";
import AccordionRoot from "./AccordionRoot";
import AccordionTrigger from "./AccordionTrigger";
import AccordionTriggerIcon from "./AccordionTriggerIcon";

const Accordion = {
  Root: AccordionRoot,
  Item: AccordionItem,
  Header: AccordionHeader,
  Trigger: AccordionTrigger,
  TriggerIcon: AccordionTriggerIcon,
  Content: AccordionContent,
};

export default Accordion;
