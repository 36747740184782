import Textarea from "../../ui/Textarea/Textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import Button from "../../ui/button/Button";
import Spinner from  '../../ui/Spinner/Spinner'
import { Comments } from "../../../services/api/CommentsRequests";
import { IComment } from "../../../types/IComment";
import { useComments } from "../../../hooks/Comments";
import { FindAllCommentsService } from "../../../services/api/FindAllCommentsService";
import { toast } from 'react-toastify';
import { FindSuggestion } from "../../../services/api/FindSuggestion";
import { useState } from "react";
import { FaRegClock } from "react-icons/fa6";
import { IoMdHelpCircleOutline } from "react-icons/io";

const formSchema = z.object({
  content: z.string(),
});

type FormSchema = z.infer<typeof formSchema>;

export interface ClassroomReplyProps {
  comment: IComment;
  onClose: () => void;
}

export const ManagerReply: React.FC<ClassroomReplyProps> = ({comment, onClose}) => {
  const {setComments} = useComments();
  const [loading, setLoading] = useState<boolean>(false);
  const { register, setValue, handleSubmit, formState: { errors, isSubmitting }, } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (formValues: FormSchema) => {
    Comments.createReply({
      ...formValues,
      reply_to_id: comment.id,
      lesson_id: comment.lesson_id,
    }).then(() => {
      onClose();
      FindAllCommentsService.execute(status).then((data) => {
        setComments(data.data);
      });
      toast.success(`Sua resposta foi salva com sucesso, e o comentário 
      também foi aprovado automaticamente.`);
    });
  };

  const handleContentChange = (value: string) => {
    if(value === '/sug.') {
      setLoading(true);
      FindSuggestion.execute({
        name: comment.user.full_name, content: comment.content
      }).then((request) => {
        setValue("content", request.content)
        setLoading(false);
      })
    }
    return value;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {loading ?
          (<div className="text-teal-500 bg-teal-50 flex py-1 px-2 items-center gap-2">
          <FaRegClock />
          <span>Aguarde enquanto providencio uma resposta para você...</span>
        </div>) : (<div className="text-teal-500 flex py-1 px-2 items-center gap-2">
        <IoMdHelpCircleOutline />
          <span>Que tal deixar a sua assistente trabalhar para você? Digite <strong>/sug.</strong></span>
        </div>)
        }
        <Textarea
        disabled={loading}
        rows={4}
        {...register("content", {
          required: true,
          onChange: (event) => {
            const { value } = event.target;
            event.target.value = handleContentChange(value);
          }
        })}
        />
      {errors.content && (<p>{errors.content.message}</p>)}
      <div className="flex justify-end gap-4 py-2">
        <Button type="submit" className="w-full md:w-auto" variant="secondary" onClick={() => onClose()}>
        Cancelar
        </Button>
        <Button type="submit" className="w-full md:w-auto">
          {isSubmitting && <Spinner size="sm" variant="secondary" />}
          <span>Enviar</span>
        </Button>
      </div>
      </form>
    </div>
  )
}