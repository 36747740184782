import { INotification } from "../../types/INotification";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  get: (url: string) =>
  ApiInstance.get<INotification[]>(url).then(ResponseBody),
};

export const FindByUserNotificationsService = {
  execute: (): Promise<INotification[]> =>
  RequestService.get('/notifications')
};

