import React from "react";
import { cn } from "../../../utils";

const ListItem = React.forwardRef<
  HTMLLIElement,
  React.HTMLAttributes<HTMLLIElement>
>(({ className, ...props }, ref) => (
  <li role="list" ref={ref} className={cn("w-full", className)} {...props} />
));

export default ListItem;
