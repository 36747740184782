import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useAuth } from "../hooks/Auth";
import { Link, useNavigate } from "react-router-dom";
import { LuMail, LuEye, LuEyeOff } from "react-icons/lu";
import Button from "../components/ui/button/Button";
import Spinner from "../components/ui/Spinner/Spinner";
import { useState } from "react";
import logoWhite from '../assets/totalpassaros-white.svg'
import logo from '../assets/totalpassaros.svg'
import { FaWhatsapp } from "react-icons/fa";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

const formSchema = z.object({
  email: z.string().email(),
  password: z.string().min(3),
});

type FormSchema = z.infer<typeof formSchema>;

export const SignIn = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [ openPassword, setOpenPassword ] = useState(false);

  const { register, handleSubmit } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (formValues: FormSchema) => {
    setLoading(true);
    signIn(formValues)
      .then(() => {
        navigate(`/${locale}/courses`);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(t('commons.toast.error'));
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex md:w-screen h-screen">
      <div className="w-1/2 bg-teal-500 md:flex-col md:justify-between px-8 py-8 hidden md:flex">
        <div className="flex items-center gap-4">
          <img src={logoWhite} className="w-56 h-auto" />
        </div>
        <div className="flex flex-col gap-6">
          <h2 className="text-4xl text-white font-semibold">
          {t('commons.brand.title')}
          </h2>
          <p className="text-white font-medium text-lg">
          {t('commons.brand.description')}
          </p>
        </div>
        <div>
          <p className="text-white">{t('commons.brand.copyright')} </p>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
        <div className="flex items-center gap-4 mb-6 md:hidden">
        <img src={logo} className="w-56 h-auto" />
        </div>
        <div className="p-6 md:p-12 w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="mb-2.5 block font-medium text-black">
              {t('pages.SignIn.mail')}
              </label>
              <div className="relative">
                <input
                  type="email"
                  placeholder=""
                  className="w-full rounded-lg border border-gray-400 bg-transparent py-4 pl-6 pr-10 outline-none focus:border-teal-400 focus-visible:shadow-none"
                  {...register("email", { required: true })}
                />
                <span className="absolute right-4 top-4">
                  <LuMail className="text-gray-400 w-5 h-5" />
                </span>
              </div>
            </div>

            <div className="mb-6">
              <label className="mb-2.5 block font-medium text-black">
              {t('pages.SignIn.password')}
              </label>
              <div className="relative">
                <input
                  type={openPassword ? 'text' : 'password'}
                  placeholder=""
                  className="w-full rounded-lg border border-gray-400 bg-transparent py-4 pl-6 pr-10 outline-none focus:border-teal-400 focus-visible:shadow-none"
                  {...register("password", { required: true })}
                />
                <span className="absolute right-4 top-4">
                  <button onClick={() => setOpenPassword(!openPassword)}>
                    {openPassword ? (<LuEye className="text-teal-500 w-5 h-5" />) :
                    (<LuEyeOff LuEye className="text-gray-400 w-5 h-5" />) }
                  </button>
                </span>
              </div>
            </div>

            <div className="mb-5">
              <Button
                type="submit"
                size="md"
                className="w-full"
                disabled={loading}
              >
                {loading && <Spinner size="sm" variant="secondary" />}
                <span>{t('pages.SignIn.buttonSignIn')}</span>
              </Button>
            </div>
            <div className="mt-6 text-center">
              <p className="font-medium">
              {t('pages.SignIn.forgotpassword.label')}
                <Link to={`/${locale}/forgotpassword`} className="text-teal-400 ml-2">
                {t('pages.SignIn.forgotpassword.link')}
                </Link>
              </p>
            </div>
            {locale === "br" && (
            <div className="flex justify-center bg-teal-50 py-4 mt-2">
            <p>Não tem uma conta? <a href="https://www.fob.org.br/MMPR/MMPRLO.aspx?Login=N" className="text-teal-500 font-bold">Registre-se</a></p>
            </div>
            )}
            <div className="p-4 mt-6 shadow rounded-lg bg-teal-50 flex flex-col gap-2">
              <h4 className="text-teal-600 font-bold">{t('pages.SignIn.help.title')}</h4>
              <p className="text-teal-600">
              {t('pages.SignIn.help.description')}
              </p>
              <a href="https://wa.me/5527995903268" className="animate-bounce bg-teal-400 font-bold  px-6 py-2 rounded-md text-white inline-flex items-center justify-center gap-2">
              <FaWhatsapp />
                <span>{t('pages.SignIn.help.whatsapp')}</span>
                </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
