import { Header } from "../components/Header/Header";
import { FindAllCommentsService } from "../services/api/FindAllCommentsService";
import { useEffect, useState } from "react";
import { IComment } from "../types/IComment";
import { useParams } from "react-router-dom";
import { CommentsProvider } from "../contexts/CommentsContext";
import { ManagerComments } from "../components/ManagerComments/ManagerComments";
import Whatsapp from "../components/Whatsapp/Whatsapp";

export const Comments = () => {
  const [comments, setComments] = useState<IComment[]>();
  const { status } = useParams();

  useEffect(() => {
   if(status) {
      FindAllCommentsService.execute(status).then((data) => {
      setComments(data.data);
    });
  }
  }, [status]);

  return (
    <div>
      <Whatsapp />
      <Header />
      <main>
       {comments && ( <CommentsProvider value={comments}>
          <ManagerComments />
        </CommentsProvider>  )}   
      </main>
    </div>
  );
};
