import { Link, NavLink } from "react-router-dom";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { HiBars3 } from "react-icons/hi2";
import { useAuth } from "../../hooks/Auth";
import Avatar from "../ui/Avatar/Avatar";
import logo from '../.../../../assets/totalpassaros.svg'
import { IoIosNotificationsOutline } from "react-icons/io";
import { useEffect, useState } from "react";
import { CountByUserNotificationsService } from "../../services/api/CountByUserNotificationsService";
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { user, signOut } = useAuth();
  const [notifications, setNotifications] = useState<{notifications: number}>();
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { t } = useTranslation();

  useEffect(() => {
    CountByUserNotificationsService.execute().then((data) => {
      setNotifications(data);
   });
   }, []);

  return (
    <header className="sticky top-0 z-[999] flex w-full bg-white drop-shadow-sm">
      <nav className="flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11 flex">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <img src={logo} className="w-56 h-auto" />
          </a>
        </div>
        <div className="lg:flex lg:gap-x-12 hidden md:flex">
          <NavLink
            to={`/${locale}/courses`}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t('commons.header.courses')}
          </NavLink>
          <NavLink
            to={`/${locale}/meetings`}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t('commons.header.meetings')}
          </NavLink>
          <NavLink
            to={`/${locale}/account`}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t('commons.header.account')}
          </NavLink>
         {user.role === 'teacher' && <NavLink
            to={`/${locale}/comments/review`}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t('commons.header.comments')}
          </NavLink>}
        </div>
        <div className="flex lg:flex-1 lg:justify-end">
          <div className="flex items-center px-2">
              <Link to={`/${locale}/notifications`} className="flex items-center justify-center h-10 w-10 rounded-full">
                <IoIosNotificationsOutline className={`w-8 h-8 text-teal-500 ${notifications?.notifications && 'animate-bounce'}`} />
              </Link>
          </div>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <button className="outline-none">
                <div className="bg-gray-50 flex md:hidden items-center justify-center w-12 h-12 rounded-lg">
                  <HiBars3 className="text-teal-400 h-6 w-6" />
                </div>
                {user && (
                  <Avatar
                    name={user.full_name}
                    src={user.profile_image_url}
                    className="hidden md:flex"
                  />
                )}
              </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Content
                sideOffset={5}
                align="end"
                className="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade min-w-[220px] rounded-md bg-white p-2 shadow-md will-change-[opacity,transform]"
              >
                <div className="flex flex-col gap-4">
                  <DropdownMenu.Item className="leading-none select-none outline-none block md:hidden">
                    <NavLink
                      to={`/${locale}/courses`}
                      className="text-base font-medium hover:text-teal-400 hover:bg-teal-50 inline-flex px-4 py-2 w-full"
                    >
                      {t('commons.header.courses')}
                    </NavLink>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item className="leading-none select-none outline-none block md:hidden">
                    <NavLink
                      to={`/${locale}/meetings`}
                      className="text-base font-medium hover:text-teal-400 hover:bg-teal-50 inline-flex px-4 py-2 w-full"
                    >
                      {t('commons.header.meetings')}
                    </NavLink>
                  </DropdownMenu.Item>
                  {user.role === 'teacher' && <DropdownMenu.Item className="leading-none select-none outline-none block md:hidden">
                    <NavLink
                      to={`/${locale}/comments/review`}
                      className="text-base font-medium hover:text-teal-400 hover:bg-teal-50 inline-flex px-4 py-2 w-full"
                    >
                      {t('commons.header.comments')}
                    </NavLink>
                  </DropdownMenu.Item>}
                  <DropdownMenu.Item className="leading-none select-none outline-none">
                    <NavLink
                      to={`/${locale}/account`}
                      className="text-base font-medium hover:text-teal-400 hover:bg-teal-50 inline-flex px-4 py-2 w-full"
                    >
                      {t('commons.header.account')}
                    </NavLink>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item className="leading-none select-none outline-none">
                    <button
                      onClick={() => signOut()}
                      className="text-base font-medium hover:text-teal-400 hover:bg-teal-50 inline-flex px-4 py-2 w-full"
                    >
                      {t('commons.header.signOut')}
                    </button>
                  </DropdownMenu.Item>
                  <DropdownMenu.Arrow className="fill-white" />
                </div>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </div>
      </nav>
    </header>
  );
};
