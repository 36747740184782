import { IComment } from "../../types/IComment";
import { ClassroomComment } from "./ClassroomComment/ClassroomComment";

export interface ClassroomCommentsProps {
  comments: IComment[];
}

export const ClassroomComments: React.FC<ClassroomCommentsProps> = ({comments}) => {
  return (
    <div className="divide-y divide-gray-200">
      {comments.map(comment => (
        <ClassroomComment key={comment.id} comment={comment} />
      ))}
    </div>
  )
}