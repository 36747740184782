import { useComments } from "../../hooks/Comments";
import Button from "../ui/button/Button";
import { FindAllCommentsService } from "../../services/api/FindAllCommentsService";
import Container from "../ui/container/Container";
import Box from "../ui/box/Box";
import { ManagerComment } from "./ManagerComment/ManagerComment";

export const ManagerComments = () => {
  const {comments, setComments } = useComments();

  const FindAllComments = (status: string) => {
    FindAllCommentsService.execute(status).then((data) => {
      setComments(data.data);
    });
  }

  return (
    <Container>
    <div className="py-4 mt-4">
    <h2 className="font-bold text-2xl">Comunidade</h2>
    </div>
    <nav className="flex space-x-4 py-2" aria-label="Tabs">
      <Button onClick={() => FindAllComments('review')}  variant="secondary">Pendentes</Button>
      <Button onClick={() => FindAllComments('approved')} variant="secondary">Aprovados</Button>
      <Button onClick={() => FindAllComments('unapproved')} variant="secondary">Reprovados</Button>
    </nav>
    {comments ? (
    <Box className="flex flex-col px-0 py-0 sm:p-0">
      <div className="flex items-center justify-between gap-6 px-5 py-6 border-b border-b-gray-100">
        <div></div>
          {comments.length > 1 ? (
            <div>Mostrando {comments.length } itens</div>
          ): (
            <div>Mostrando {comments.length } item</div>
          )}
      </div>
      <div className="flex flex-col divide-y divide-y-gray-50">
        {comments.map((comment) => (
          <ManagerComment comment={comment} />
        ))}
      </div>
    </Box>
    ): (
      <Box>Sem comenario </Box>
    )}
  </Container>
  )
}