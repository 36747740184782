import { IResetPasswordService } from "../../types/IResetPasswordService";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  post: (url: string, body: IResetPasswordService) =>
    ApiInstance.post(url, body).then(ResponseBody),
};

export const ResetPasswordService = {
  execute: (request: IResetPasswordService): Promise<void> =>
  RequestService.post('/password/reset', request)
};
