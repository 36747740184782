import { parseISO } from 'date-fns'; 
import { format } from 'date-fns-tz';
import { FaRegCalendarCheck } from "react-icons/fa";
import { IFindAllMeetingsResponse } from "../../types/IFindAllMeetingsResponse";
import { useTranslation } from 'react-i18next';

export const MeetingInProgress: React.FC<IFindAllMeetingsResponse> = ({
  title,
  meeting_date,
  participants
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center gap-6 py-2 ">
      <div className="flex items-center gap-4">
        <FaRegCalendarCheck className="text-teal-500 animate-bounce" />
        <span className="bg-teal-200 text-teal-800 px-4 py-1 rounded-full">
        {format(parseISO(`${meeting_date}`), 'dd/MM/yyyy', {timeZone: 'America/Sao_Paulo'})}
        </span>
        <h4 className="font-medium">{title}l</h4>
      </div>
      {participants && participants.length && (
       <a href={participants[0].join_url} className="inline-flex gap-2 items-center justify-center rounded-md focus:outline-none transition ease-in-out duration-150 enabled:hover:bg-opacity-90 disabled:opacity-75 bg-[#2d8cff] text-white px-6 py-3 text-sm">
        {t('pages.Meetings.inProgress')} 
       </a>
      )}
    </div>
  )
}