import { IFindSuggestionRequest } from "../../types/IFindSuggestionRequest";
import { IFindSuggestionResponse } from "../../types/IFindSuggestionResponse";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  post: (url: string, body: IFindSuggestionRequest) =>
    ApiInstance.post(url, body).then(ResponseBody),
};

export const FindSuggestion = {
  execute: (request: IFindSuggestionRequest): Promise<IFindSuggestionResponse> =>
  RequestService.post('/comments/suggestion', request)
};
