import { useState } from "react";
import { IComment } from "../../../types/IComment";
import { DeleteCommentService } from "../../../services/api/DeleteCommentService";
import { ApprovalCommentService } from "../../../services/api/ApprovalCommentService";
import { IApprovalCommentService } from "../../../types/IApprovalCommentService";
import Avatar from "../../ui/Avatar/Avatar";
import Button from "../../ui/button/Button";
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useComments } from "../../../hooks/Comments";
import { ManagerReply } from "../ManagerReply/ManagerReply";
import {LuMessageSquare, LuThumbsUp, LuThumbsDown, LuTrash2} from "react-icons/lu";
import { Link } from "react-router-dom";

export interface ManagerCommentProps {
  comment: IComment;
}

export const ManagerComment: React.FC<ManagerCommentProps> = ({comment}) => {
  const {comments, setComments } = useComments();
  const [showReply, setShowReply] = useState<boolean>(false)

  const handleDeleteComment = (id: number) => {
    {comments &&  (DeleteCommentService.execute(id).then(() => {
      setComments(comments.filter(comment => comment.id !== id))
    }))}
  };

  const handleApprovalComment = (id: number, status: IApprovalCommentService) => {
    {comments &&  (ApprovalCommentService.execute(id, status).then(() => {
      setComments(comments.filter(comment => comment.id !== id))
    }))}
  };

  return (
    <div className="flex px-5 py-4 gap-4" key={comment.id}>
    <div>
    <Avatar name={comment.user.full_name} src={comment.user.profile_image_url} size="lg" />
    </div>
    <div className="flex flex-col gap-4 flex-1">
    <div className="flex items-center justify-between ga-6">
      <h3 className="font-bold">{comment.user.full_name} <span className="ml-2 font-normal">Perguntou em <Link to={`/lesson/${comment.lesson.module.course_id}/${comment.lesson.id}`} className="text-teal-500">{comment.lesson.title}</Link></span></h3>
      <span>{format(parseISO(comment.updated_at.toString()), "'Dia' dd 'de' MMMM yyyy", {locale: ptBR})}</span>
    </div>
    <div>
    {comment.content}
    </div>
      <div className="inline-flex gap-2">
        <Button onClick={() => setShowReply(!showReply)} variant="secondary" size="sm" className="px-2"><LuMessageSquare /></Button>
        <Button onClick={() => handleApprovalComment(comment.id, {status: 'approved'})} variant="secondary" size="sm" className="px-2"><LuThumbsUp /></Button>
        <Button onClick={() => handleApprovalComment(comment.id, {status: 'unapproved'})} variant="secondary" size="sm" className="px-2"><LuThumbsDown /></Button>
        <Button onClick={() => handleDeleteComment(comment.id)} variant="secondary" size="sm" className="px-2"><LuTrash2 /></Button>
      </div>
     {showReply && <ManagerReply comment={comment} onClose={() => setShowReply(false)} />}
    </div>
    </div>
  )
}