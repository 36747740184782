import { useEffect, useState } from "react";
import Container from "../components/ui/container/Container";
import Box from "../components/ui/box/Box";
import { Link } from "react-router-dom";
import { Header } from "../components/Header/Header";
import Skeleton from "../components/ui/Skeleton/Skeleton";
import { toast } from "react-toastify";
import { FaRegCalendarAlt } from "react-icons/fa";
import Whatsapp from "../components/Whatsapp/Whatsapp";
import { useTranslation } from 'react-i18next';
import { FindAllEnrollmentsService } from "../services/api/FindAllEnrollmentsService";
import { IEnrollment } from "../types/IEnrollment";

export const CoursesPage = () => {
  const [enrollments, setEnrollments] = useState<IEnrollment[]>();
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  useEffect(() => {
    FindAllEnrollmentsService.execute().then((data) => {
      setEnrollments(data);
    }).catch(() => {
      toast.error(t('commons.toast.error'));
    })
  }, []);

  return (
    <div>
      <Whatsapp />
      <Header />
      <main>
        <Container>
          <div className="flex flex-wrap pt-6 md:pt-20 lg:-mx-6">
            <Link to="/meetings" className="w-full md:hidden">
              <div className="flex gap-4 items-center justify-center bg-teal-400 mb-6 p-2  rounded-xl">
                <FaRegCalendarAlt className="text-white w-8 h-8" />
                <h2 className="text-sm text-white">{t('pages.Courses.meetings.notification')}</h2>
              </div>
            </Link>

            <div className="grid grid-cols-1 gap-8 md:grid-cols-3 w-full">
              {enrollments ? (
                enrollments.map(({course}) => (
                  <Box
                    key={course.id}
                    className="divide-y-gray-100 divide-y flex flex-col"
                  >
                    <div className="py-4 h-24 flex items-center">
                      <h2 className="text-2xl">{course.name}</h2>
                    </div>
                    <div className="py-6 flex-1">
                      <p>{course.description}</p>
                    </div>
                    <div className="flex justify-end pt-4">
                      <Link to={`/${locale}/course/${course.id}`}>{t('pages.Courses.access')}</Link>
                    </div>
                  </Box>
                ))
              ) : (
                <>
                  <Box className="divide-y-gray-100 divide-y flex flex-col">
                    <div className="py-4 h-24 flex items-center">
                      <Skeleton className="h-4 w-full" />
                    </div>
                    <div className="py-6 flex-1 flex flex-col gap-4">
                      <Skeleton className="h-2 w-full" />
                      <Skeleton className="h-2 w-2/3" />
                      <Skeleton className="h-2 w-1/3" />
                    </div>
                    <div className="flex justify-end pt-4">
                      <Skeleton className="h-6 w-28" />
                    </div>
                  </Box>
                  <Box className="divide-y-gray-100 divide-y flex flex-col">
                    <div className="py-4 h-24 flex items-center">
                      <Skeleton className="h-4 w-full" />
                    </div>
                    <div className="py-6 flex-1 flex flex-col gap-4">
                      <Skeleton className="h-2 w-full" />
                      <Skeleton className="h-2 w-2/3" />
                      <Skeleton className="h-2 w-1/3" />
                    </div>
                    <div className="flex justify-end pt-4">
                      <Skeleton className="h-6 w-28" />
                    </div>
                  </Box>
                </>
              )}
              <Box
                    className="bg-teal-400 divide-teal-500 divide-y flex flex-col"
                  >
                    <div className="py-4 h-24 flex items-center justify-between">
                      <h2 className="text-2xl text-white">{t('pages.Courses.meetings.title')}</h2>
                      <FaRegCalendarAlt className="text-teal-500 opacity-30 w-20 h-20" />
                    </div>
                    <div className="py-6 flex-1 text-white">
                      <p>{t('pages.Courses.meetings.description')}</p>
                    </div>
                    <div className="flex justify-end pt-4 text-white">
                      <Link to={`/${locale}/meetings`}>{t('pages.Courses.access')}</Link>
                    </div>
              </Box>
            </div>
          </div>
        </Container>
      </main>
    </div>
  );
};
