import React from "react";
import { cn } from "../../../utils";

const Text = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-neutral-700", className)}
    {...props}
  />
));

export default Text;
