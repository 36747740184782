import { useEffect, useState } from "react";
import Container from "../components/ui/container/Container";
import { IModule } from "../types/IModule";
import { ICourse } from "../types/ICourse";
import Accordion from "../components/ui/accordion";
import Text from "../components/ui/text/Text";
import { Courses } from "../services/api/CoursesRequests";
import { Link, useParams } from "react-router-dom";
import List from "../components/ui/list";
import { LuPlayCircle } from "react-icons/lu";
import { Header } from "../components/Header/Header";
import Spinner from "../components/ui/Spinner/Spinner";
import * as Dialog from "@radix-ui/react-dialog";
import { toast } from "react-toastify";
import Whatsapp from "../components/Whatsapp/Whatsapp";
import { useTranslation } from 'react-i18next';

export const CoursePage = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [course, setCourse] = useState<ICourse>();
  const [modules, setModules] = useState<IModule[]>();
  const locale = i18n.language;

  useEffect(() => {
    if (id) {
      Courses.FindById(parseInt(id)).then((data) => {
        setCourse(data);
        setModules(data.modules);
      }).catch(() => {
        toast.error(t('commons.toast.error'));
      })
    }
  }, []);

  return (
    <div>
      <Whatsapp />
      <Header />
      <main>
        <Container>
          {course ? (
            <div className="flex flex-wrap md:pt-20 lg:-mx-6">
              <div className="w-full lg:w-4/12 lg:px-6 py-8 md:py-0">
                <h3 className="text-xl font-medium tracking-tight">
                  {course.name}
                </h3>
                <Text>{course.description}</Text>
              </div>
              <div className="w-full lg:w-8/12 lg:px-6">
                <div className="flex flex-col gap-4">
                  {modules &&
                    modules.map((module) => (
                      <Accordion.Root
                        accessKey={`module-${module.id}`}
                        type="single"
                        collapsible
                        key={module.id}
                      >
                        <Accordion.Item value="1">
                          <Accordion.Header>
                            <Accordion.Trigger>
                              <Accordion.TriggerIcon />
                              <Text>{module.name}</Text>
                            </Accordion.Trigger>
                          </Accordion.Header>
                          <Accordion.Content>
                            <List.Root className="divide-y divide-gray-100">
                              {module.lessons &&
                                module.lessons.map((lesson) => (
                                  <List.Item
                                    className="flex items-center gap-2 px-5 py-4"
                                    key={lesson.id}
                                  >
                                    <div>
                                      <div className={`flex h-8 w-8 items-center justify-center rounded-full ${lesson.users_lessons.length && lesson.users_lessons[0].has_watched === true ? 'bg-green-300' : 'bg-gray-100'}`}>
                                        <LuPlayCircle className={`h-6 w-6 ${lesson.users_lessons.length && lesson.users_lessons[0].has_watched === true ? 'text-green-600' : 'text-gray-500' }`} />
                                      </div>
                                    </div>
                                    <Link
                                      to={`/${locale}/lesson/${course && course.id}/${lesson.id}`}
                                      className="hover:text-green-600"
                                    >
                                      {lesson.title}
                                    </Link>
                                  </List.Item>
                                ))}
                            </List.Root>
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion.Root>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <Dialog.Root open={true}>
              <Dialog.Trigger />
              <Dialog.Portal>
                <Dialog.Overlay className="bg-gray-100 opacity-50 fixed inset-0" />
                <Dialog.Content className="fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-gray-50 p-[25px] shadow-sm focus:outline-none">
                  <div className="flex items-center justify-center gap-4">
                    <Spinner size="md" variant="primary" />
                    <span className="text-teal-400">{t('pages.Course.loading')}</span>
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          )}
        </Container>
      </main>
    </div>
  );
};
