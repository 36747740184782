
import { IEnrollment } from "../../types/IEnrollment";
import { IFindAllEnrollmentsService } from "../../types/IFindAllEnrollmentsService";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  get: (url: string) =>
  ApiInstance.get<IFindAllEnrollmentsService>(url).then(ResponseBody),
};

export const FindAllEnrollmentsService = {
  execute: (): Promise<IEnrollment[]> =>
  RequestService.get('/enrollments')
};


