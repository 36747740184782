import { ISendForgotPasswordEmail } from "../../types/ISendForgotPasswordEmail";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  post: (url: string, body: ISendForgotPasswordEmail) =>
    ApiInstance.post(url, body).then(ResponseBody),
};

export const SendForgotPasswordEmail = {
  execute: (request: ISendForgotPasswordEmail): Promise<void> =>
  RequestService.post('/password/forgot', request)
};
