import React from "react";
import { cn } from "../../../utils";

const Skeleton = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("animate-pulse rounded-md bg-gray-100", className)}
    {...props}
  />
));

export default Skeleton;
