import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "../hooks/Toast";
import { LuEye, LuEyeOff  } from "react-icons/lu";
import Button from "../components/ui/button/Button";
import Spinner from "../components/ui/Spinner/Spinner";
import { useState } from "react";
import { ResetPasswordService } from "../services/api/ResetPasswordService";
import logoWhite from '../assets/totalpassaros-white.svg'
import logo from '../assets/totalpassaros.svg'
import { useTranslation } from 'react-i18next';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [ openPassword, setOpenPassword ] = useState(false);
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  
  const token = searchParams.get('token')


  const formSchema = z.object({
    password: z.string()
    .min(8, {message: t('pages.ResetPassword.formSchema.min')})
    .max(30, {message: t('pages.ResetPassword.formSchema.max')}),
    // .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, {
    //   message: `A senha não atende aos requisitos mínimos de segurança, que incluem 
    //   pelo menos 8 caracteres, uma letra minúscula, uma letra maiúscula, um número e 
    //   um caractere especial (@, $, !, %, *, ?, ou &).`
    // }),
    password_confirmation: z.string().min(3).max(30)
  }).refine((data) => data.password === data.password_confirmation, {
    message: t('pages.ResetPassword.formSchema.password_confirmation'),
    path: ["password_confirmation"],
  });
  
  type FormSchema = z.infer<typeof formSchema>;

  

  const { register, handleSubmit, formState: { errors }, } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  });

  const onSubmit = async (formValues: FormSchema) => {
    setLoading(true);
    if(token){
      ResetPasswordService.execute({...formValues, token})
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        if (error.response) {
          toast({
            description: error.response.data.message,
            variant: "destructive",
          });
        } else {
          toast({
            description: t('commons.toast.unexpectedError'),
            variant: "destructive",
          });
        }
      })
      .finally(() => setLoading(false));
    }
  };

  return (
    <div className="flex md:w-screen h-screen">
      <div className="w-1/2 bg-teal-500 md:flex-col md:justify-between px-8 py-8 hidden md:flex">
        <div className="flex items-center gap-4">
          <img src={logo} className="w-56 h-auto" />
        </div>
        <div className="flex flex-col gap-6">
          <h2 className="text-5xl text-white font-semibold">
            {t('commons.brand.title')}
          </h2>
          <p className="text-white font-medium text-lg">
          {t('commons.brand.description')}
          </p>
        </div>
        <div>
          <p className="text-white">
          {t('commons.brand.copyright')}
          </p>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
        <div className="flex items-center gap-4 mb-6 md:hidden">
            <img src={logoWhite} className="w-56 h-auto" />
        </div>
        <div className="p-6 md:p-12 w-full">
        <div className="flex flex-col justify-start py-4">
          <h2 className="text-base font-bold">{t('pages.ResetPassword.title')}</h2>
          {t('pages.ResetPassword.description')}
        </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <label className="mb-2.5 block font-medium text-black">
              {t('pages.ResetPassword.newPassword')}
              </label>
              <div className="relative">
                <input
                  type={openPassword ? 'text' : 'password'}
                  placeholder=""
                  className="w-full rounded-lg border border-gray-400 bg-transparent py-4 pl-6 pr-10 outline-none focus:border-teal-400 focus-visible:shadow-none"
                  {...register("password", { required: true })}
                />
                <span className="absolute right-4 top-4">
                  <button onClick={() => setOpenPassword(!openPassword)}>
                    {openPassword ? (<LuEye className="text-teal-500 w-5 h-5" />) :
                    (<LuEyeOff LuEye className="text-gray-400 w-5 h-5" />) }
                  </button>
                </span>
              </div>
              {errors.password && (<p className="text-red-500 py-1">{errors.password.message}</p>)}
            </div>

            <div className="mb-6">
              <label className="mb-2.5 block font-medium text-black">
              {t('pages.ResetPassword.passwordConfirmation')}
              </label>
              <div className="relative">
                <input
                  type={openPassword ? 'text' : 'password'}
                  placeholder=""
                  className="w-full rounded-lg border border-gray-400 bg-transparent py-4 pl-6 pr-10 outline-none focus:border-teal-400 focus-visible:shadow-none"
                  {...register("password_confirmation", { required: true })}
                />
                <span className="absolute right-4 top-4">
                  {openPassword ? (<LuEye className="text-teal-500 w-5 h-5" />) :
                    (<LuEyeOff LuEye className="text-gray-400 w-5 h-5" />) }
                </span>
              </div>
              {errors.password_confirmation && (<p className="text-red-500 py-1">{errors.password_confirmation.message}</p>)}
            </div>

            <div className="mb-5">
              <Button
                type="submit"
                size="md"
                className="w-full"
                disabled={loading}
              >
                {loading && <Spinner size="sm" variant="secondary" />}
                <span>
                {t('pages.ResetPassword.changeMyPassword')}
                </span>
              </Button>
            </div>
            <div className="mt-6 text-center">
              <p className="font-medium">
              {t('pages.ResetPassword.forgotYourPassword.label')}
                <Link to={`/${locale}/forgotpassword`} className="text-teal-400 ml-2">
                {t('pages.ResetPassword.forgotYourPassword.link')}
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
