import { useRef } from 'react';
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useToast } from "../hooks/Toast";
import { LuContact } from "react-icons/lu";
import Button from "../components/ui/button/Button";
import Spinner from "../components/ui/Spinner/Spinner";
import { useState } from "react";
import { LuMail } from "react-icons/lu";
import { RegisterService } from "../services/api/RegisterService";
import { Player } from '@lottiefiles/react-lottie-player';
import logoWhite from '../assets/totalpassaros-white.svg'
import logo from '../assets/totalpassaros.svg'
import { FaWhatsapp } from "react-icons/fa";

import send from '../assets/send.json';
import confetti from '../assets/confetti.json';

const formSchema = z.object({
  full_name: z.string().min(1, {message: 'Por favor, preencha todos os campos obrigatórios'}),
  email: z.string().email({message: 'Certifique-se de inserir um endereço de e-mail válido.'}),
});

type FormSchema = z.infer<typeof formSchema>;

export const Register = () => {
  const { toast } = useToast();
  const [success, setSuccess] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token')

  const playerRef = useRef<Player>(null);

  const { register, handleSubmit, formState: { errors, isSubmitting }, } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  });

  const onSubmit = async (formValues: FormSchema) => {
    if(token){
      RegisterService.execute({...formValues, token})
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        if (error.response) {
          toast({
            description: error.response.data.message,
            variant: "destructive",
          });
        } else {
          toast({
            description:
              "Ocorreu um erro inesperado, tente novamente mais tarde.",
            variant: "destructive",
          });
        }
      })
    }
  };

  return (
    <div className="flex md:w-screen h-screen">
      <div className="w-1/2 bg-teal-500 md:flex-col md:justify-between px-8 py-8 hidden md:flex">
        <div className="flex items-center gap-4">
          <img src={logoWhite} className="w-56 h-auto" />
        </div>
        <div className="flex flex-col gap-6">
          <h2 className="text-5xl text-white font-semibold">
            CRIAÇÃO DE CANÁRIOS
          </h2>
          <p className="text-white font-medium text-lg">
            Domine as técnicas de manejo, nutrição, seleção genética, critérios
            de avaliação de concurso e conte com nosso suporte e assistência
            técnica.
          </p>
        </div>
        <div>
          <p className="text-white">© 2023 Todos os Direitos Reservados </p>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
        {success ? (
          <div className="bg-teal-50 rounded-xl shadow px-10 py-11 w-full md:w-2/3">
              <Player
              autoplay
              loop={true}
              keepLastFrame={true}
              src={send}
              ref={playerRef}
              style={{ height: '200px', width: '200px' }}
              />
              <h3 className='text-teal-700 font-bold text-center'>
              Parabéns, seu cadastro foi realizado com sucesso. Você receberá um e-mail com todas as informações de acesso em breve. 
              </h3>

              <p className='text-sm text-center mt-6 text-teal-700'>
              Confira a sua caixa de entrada. Se não encontrar a mensagem, verifique a pasta de lixo eletrônico ou SPAM. Caso não receba nosso e-mail nos próximos minutos, por favor, entre em contato conosco.
              </p>
              <Player
              autoplay
              loop={true}
              keepLastFrame={true}
              src={confetti}
              ref={playerRef}
              />
              <div className='pt-4'>
              <a href="https://wa.me/5527995903268" className=" bg-teal-400 font-bold  px-6 py-2 rounded-md text-white inline-flex items-center justify-center gap-2 w-full">
              <FaWhatsapp />
                <span>QUERO TIRAR UMA DÚVIDA</span>
                </a>
              </div>
          </div>
        ) : (
          <div>
          <div className="flex items-center gap-4 mb-6 md:hidden px-2">
            <img src={logo} className="w-56 h-auto" />
        </div>
        <div className="p-6 md:p-12 w-full">
        <div className="flex flex-col justify-start py-4">
          <h2 className="text-base font-bold">Complete os dados para criar sua conta</h2>
          Para finalizar seu cadastro, por favor, preencha o formulário abaixo. Estamos ansiosos para tê-lo(a) fazendo parte da nossa comunidade!
        </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <label className="mb-2.5 block font-medium text-black">
              Nome
              </label>
              <div className="relative">
                <input
                  type="text"
                  placeholder=""
                  className="w-full rounded-lg border border-gray-400 bg-transparent py-4 pl-6 pr-10 outline-none focus:border-teal-400 focus-visible:shadow-none"
                  {...register("full_name", { required: true })}
                />
                <span className="absolute right-4 top-4">
                  <LuContact className="text-gray-400 w-5 h-5" />
                </span>
              </div>
              {errors.full_name && (<p className="text-red-500 py-1">{errors.full_name.message}</p>)}
            </div>

            <div className="mb-4">
              <label className="mb-2.5 block font-medium text-black">
                Seu e-mail
              </label>
              <p className="text-sm text-gray-600 py-2">
              Informe seu e-mail pessoal, pois será por meio dele que você terá acesso aos dados de login.
              </p>
              <div className="relative">
                <input
                  type="email"
                  placeholder=""
                  className="w-full rounded-lg border border-gray-400 bg-transparent py-4 pl-6 pr-10 outline-none focus:border-teal-400 focus-visible:shadow-none"
                  {...register("email", { required: true })}
                />
                <span className="absolute right-4 top-4">
                  <LuMail className="text-gray-400 w-5 h-5" />
                </span>
              </div>
              {errors.email && (<p className="text-red-500 py-1">{errors.email.message}</p>)}
            </div>

            <div className="mb-5">
              <Button
                type="submit"
                size="md"
                className="w-full"
                disabled={isSubmitting}
              >
                {isSubmitting && <Spinner size="sm" variant="secondary" />}
                <span>Continuar</span>
              </Button>
            </div>
          </form>
        </div>
          </div>
        )}
      </div>
    </div>
  );
};
