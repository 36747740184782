import { useEffect, useState } from "react";
import { useClassRoom } from "../../hooks/ClassRoom";
import IframeLoading from "./IframeLoading";

export const Player = () => {
  const [src, setSrc] = useState("");
  const [loading, setLoading] = useState(true);
  const { lesson } = useClassRoom();

  useEffect(() => {
    if (src !== lesson.video) {
      setLoading(true);
      setSrc(lesson.video);
    }
  }, [lesson, src]);

  return (
    <div className="bg-secondary px-0 md:px-4 overflow-hidden rounded-lg">
      <IframeLoading src={src} isLoading={loading} />
    </div>
  );
};
