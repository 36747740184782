import { IComment } from "../../types/IComment";
import { IRequestCreateComment } from "../../types/IRequestCreateComment";
import { IRequestCreateReply } from "../../types/IRequestCreateReply";
import { IRequestUpdateComment } from "../../types/IRequestUpdateComment";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const CommentsRequests = {
  post: (url: string, body: IRequestCreateComment) =>
    ApiInstance.post<IComment>(url, body).then(ResponseBody),
  createReply: (url: string, body: IRequestCreateReply) =>
    ApiInstance.post<IComment>(url, body).then(ResponseBody),
  put: (url: string, body: IRequestUpdateComment) =>
    ApiInstance.put<IComment>(url, body).then(ResponseBody),
  FindById: (url: string) => ApiInstance.get<IComment>(url).then(ResponseBody),
  delete: (url: string) => ApiInstance.delete(url).then(),
};

export const Comments = {
  createComment: (comment: IRequestCreateComment): Promise<IComment> =>
    CommentsRequests.post(`/comments`, comment),
  createReply: (comment: IRequestCreateReply): Promise<IComment> =>
    CommentsRequests.post(`/replies`, comment),
  updateComment: (
    id: number,
    comment: IRequestUpdateComment
  ): Promise<IComment> => CommentsRequests.put(`/comments/${id}`, comment),
  FindById: (id: number): Promise<IComment> =>
    CommentsRequests.FindById(`/comments/${id}`),
  deleteComment: (id: number) => CommentsRequests.delete(`/comments/${id}`),
};
