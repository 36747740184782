import React from "react";
import { tv, VariantProps } from "tailwind-variants";
import { cn } from "../../../utils";

const avatarVariants = tv({
  base: "flex items-center justify-center rounded-full bg-gray-300 text-gray-500 overflow-hidden",
  variants: {
    size: {
      xs: "h-6 w-6 text-xs",
      sm: "h-8 w-8 text-sm",
      md: "h-10 w-10 text-base",
      lg: "h-12 w-12 text-lg",
      lx: "h-14 w-14 text-xl",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export interface AvatarProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof avatarVariants> {
  name: string;
  src: string | null;
}

const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  ({ className, size, src, name, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(avatarVariants({ size }), className)}
      {...props}
    >
      {src ? <img src={src} alt={name} /> : <span> {name}</span>}
    </div>
  )
);

export default Avatar;
