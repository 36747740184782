import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useClassRoom } from "../../../hooks/ClassRoom";
import { Comments } from "../../../services/api/CommentsRequests";
import Button from "../../ui/button/Button";
import Spinner from  '../../ui/Spinner/Spinner'
import { useComments } from "../../../hooks/Comments";
import { FindCommentsByLessonService } from "../../../services/api/FindCommentsByLessonService";
import { useTranslation } from 'react-i18next';

const formSchema = z.object({
  content: z.string(),
});

type FormSchema = z.infer<typeof formSchema>;

export const ClassroomCommentCreate = () => {
  const { lesson } = useClassRoom();
  const [show, setShow] = useState<boolean>(false);
  const {setComments} = useComments();
  const { t } = useTranslation();

  const { register, handleSubmit, reset ,formState: { isSubmitting } } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (formValues: FormSchema) => {
    Comments.createComment({...formValues, lesson_id: lesson.id})
      .then(() => {
        FindCommentsByLessonService.execute(lesson.id).then((comments) => {
          setComments(comments)
        })
        reset()
      })
  };

  return (
    <div className="py-4 px-2 md:px-6">
      {show ? (
        <>
        <div className="py-4">
        <p>
          {t('pages.Lesson.question')}
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4">
          <div>
            <textarea
              rows={4}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              {...register("content", { required: true })}
            />
          </div>
          <div className="flex flex-col-reverse md:flex-row justify-end gap-4">
            <Button
              variant="secondary"
              disabled={isSubmitting}
              onClick={() => setShow(false)}
            >
              {t('pages.Lesson.cancel')}
            </Button>
            <Button type="submit">
              {isSubmitting && <Spinner size="sm" variant="secondary" />}
              <span>{t('pages.Lesson.save')}</span>
            </Button>
          </div>
        </div>
      </form>
        </>
      ): <Button onClick={() => setShow(true)} variant="secondary">{t('pages.Lesson.addComment')}</Button>}
    </div>
  );
};