import Textarea from "../../ui/Textarea/Textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import Button from "../../ui/button/Button";
import Spinner from  '../../ui/Spinner/Spinner'
import { Comments } from "../../../services/api/CommentsRequests";
import { IComment } from "../../../types/IComment";
import { FindCommentsByLessonService } from "../../../services/api/FindCommentsByLessonService";
import { useComments } from "../../../hooks/Comments";
import { useClassRoom } from "../../../hooks/ClassRoom";
import { useTranslation } from 'react-i18next';

const formSchema = z.object({
  content: z.string(),
});

type FormSchema = z.infer<typeof formSchema>;

export interface ClassroomCommentEditProps {
  comment: IComment;
  onClose: () => void;
}

export const ClassroomCommentEdit: React.FC<ClassroomCommentEditProps> = ({comment, onClose}) => {
  const { setComments } = useComments();
  const { lesson } = useClassRoom();
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const { t } = useTranslation();

  const onSubmit = async (formValues: FormSchema) => {
    if (comment) {
      Comments.updateComment(comment.id, formValues)
        .then(() => {
          onClose()
          FindCommentsByLessonService.execute(lesson.id).then((comments) => {
            setComments(comments)
          })
        })
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Textarea
        rows={4}
        {...register("content", {
          required: true,
          value: comment.content,
        })}
        />
      {errors.content && (<p>{errors.content.message}</p>)}
      <div className="flex justify-end gap-4 py-2">
        <Button type="submit" className="w-full md:w-auto" variant="secondary" onClick={() => onClose()}>
        {t('pages.Lesson.cancel')}
        </Button>
        <Button type="submit" className="w-full md:w-auto">
          {isSubmitting && <Spinner size="sm" variant="secondary" />}
          <span> {t('pages.Lesson.editQuestion')}</span>
        </Button>
      </div>
      </form>
    </div>
  )
}