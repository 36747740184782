import { useEffect, useState } from "react";
import { useComments } from "../../../hooks/Comments";
import { ClassroomComments } from "../ClassroomComments"
import { BuildCommentTreeAsync } from "../../../utils";
import { IComment } from "../../../types/IComment";
import { FindCommentsByLessonService } from "../../../services/api/FindCommentsByLessonService";
import { useParams } from "react-router-dom";

export const ClassroomCommentsStore = () => {
  const { lesson_id } = useParams();
  const {comments, setComments} = useComments();
  const [commentTree, setCommentTree] = useState<IComment[]>();

  useEffect(() => {
    lesson_id && FindCommentsByLessonService.execute(parseInt(lesson_id)).then((data) => {
      setComments(data as IComment[]);
    });
  }, [lesson_id, setComments]);

  useEffect(() => {
    comments && BuildCommentTreeAsync(comments).then((data) => {
      setCommentTree(data as IComment[])
    })
  },[commentTree, comments])

  return (commentTree && <ClassroomComments comments={commentTree?.slice().reverse()} />)
}