import { z } from "zod";
import Input from "../components/ui/Input/Input";
import Box from "../components/ui/box/Box";
import Button from "../components/ui/button/Button";
import Container from "../components/ui/container/Container";
import { useCallback, useEffect, useState } from "react";
import { IProfile } from "../types/IProfile";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ApiInstance } from "../services/api/ApiInstances";
import { profile } from "../services/api/ProfileRequests";
import Dropzone from "react-dropzone";
import { Header } from "../components/Header/Header";
import Spinner from  '../components/ui/Spinner/Spinner';
import { toast } from "react-toastify";
import Whatsapp from "../components/Whatsapp/Whatsapp";
import { useTranslation } from 'react-i18next';

export const Account = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState<IProfile | null>();
  const [progress, setProgress] = useState<number>(0);

  const formSchema = z.object({
    full_name: z.string().min(1, {message: t('pages.Account.formSchema.requiredFields')}),
    email: z.string().email({message: t('pages.Account.formSchema.validMail')}),
    old_password: z.string().min(3,{message: t('pages.Account.formSchema.requiredFields')}),
    password: z.string().min(3,{message: t('pages.Account.formSchema.requiredFields')}),
    password_confirmation: z.string().min(3,{message: t('pages.Account.formSchema.requiredFields')}),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: t('pages.Account.formSchema.passwordConfirmation'),
    path: ["password_confirmation"],
  });
  
  type FormSchema = z.infer<typeof formSchema>;

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  });

  const onSubmit = async (formValues: FormSchema) => {
    profile
      .updateProfile(formValues)
      .then(() => {
        toast.success(t('pages.Account.toast.success'));
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(t('pages.Account.toast.error'));
        }
      });
  };

  const handleUpload = useCallback((acceptedFiles: File[]) => {
    if (typeof acceptedFiles[0] === "undefined") return;

    const formData = new FormData();
    formData.append("avatar", acceptedFiles[0]);

    ApiInstance.patch("/users/avatar", formData, {
      onUploadProgress: (e) => {
        if (e.total) {
          const progress = Math.round((e.loaded * 100) / e.total);
          setProgress(progress);
        }
      },
    })
      .then((response) => {
        setUser(response.data);
        setProgress(0);
      })
      .catch(() => {
        toast.error(t('pages.Account.toast.error'));
      });
  }, []);

  useEffect(() => {
    profile.getProfile().then((data) => {
      setUser(data);
    }).catch(() => {
      toast.error(t('pages.Account.toast.error'));
    })
  }, []);

  return (
    <div>
      <Whatsapp />
      <Header />
      <main>
        <Container>
          {user && (
            <div className="flex flex-wrap md:pt-20 lg:-mx-6 py-4">
              <div className="flex w-full justify-center">
                <Box className="w-full md:w-1/2 divide-y divide-gray-100">
                  <div className="flex items-center gap-4 py-6">
                    <div className="flex h-24 w-24 items-center justify-center overflow-hidden rounded-full bg-gray-100">
                      {user.profile_image_url && (
                        <img
                          src={user.profile_image_url}
                          alt={user.full_name}
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <div>{t('pages.Account.upload')}</div>
                      {progress > 0 && (
                        <div className="h-1 w-full overflow-hidden rounded-md bg-gray-50">
                          <div
                            className="h-1 bg-teal-200"
                            style={{ width: `${progress}%` }}
                          ></div>
                        </div>
                      )}
                      <div>
                        <Dropzone
                          multiple={false}
                          accept={{ "image/*": [".jpeg", ".png"] }}
                          onDrop={(acceptedFiles) =>
                            handleUpload(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              className="w-[200px] rounded-md"
                            >
                              <input {...getInputProps()} />
                              <Button className="py-2">{t('pages.Account.ChangePhoto')}</Button>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>
                  <div className="py-4">
                    <h3 className="text-lg font-medium">{t('pages.Account.title')}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-2">
                          <label htmlFor="">{t('pages.Account.FullName')}</label>
                          <Input
                            type="text"
                            {...register("full_name", {
                              required: true,
                              value: user.full_name,
                            })}
                          />
                          {errors.full_name && (<p className="text-red-500">{errors.full_name.message}</p>)}
                        </div>

                        <div className="flex flex-col gap-2">
                          <label htmlFor="">{t('pages.Account.mail')}</label>
                          <Input
                            type="text"
                            {...register("email", {
                              required: true,
                              value: user.email,
                            })}
                          />
                           {errors.email && (<p className="text-red-500">{errors.email.message}</p>)}
                        </div>

                        <div className="flex flex-col gap-2">
                          <label htmlFor="">{t('pages.Account.password')}</label>
                          <Input
                            type="password"
                            {...register("old_password", { required: true })}
                          />
                           {errors.old_password && (<p className="text-red-500">{errors.old_password.message}</p>)}
                        </div>

                        <div className="flex flex-col gap-2">
                          <label htmlFor="">{t('pages.Account.newPassword')}</label>
                          <Input
                            type="password"
                            {...register("password", { required: true })}
                          />
                          {errors.password && (<p className="text-red-500">{errors.password.message}</p>)}
                        </div>

                        <div className="flex flex-col gap-2">
                          <label htmlFor="">{t('pages.Account.confirmPassword')}</label>
                          <Input
                            type="password"
                            {...register("password_confirmation", {
                              required: true,
                            })}
                          />
                          {errors.password_confirmation && (<p className="text-red-500">{errors.password_confirmation.message}</p>)}
                        </div>
                        <div>
                          <Button type="submit" className="w-full md:w-auto">
                          {isSubmitting && <Spinner size="sm" variant="secondary" />}
                            <span>{t('pages.Account.save')}</span>
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Box>
              </div>
            </div>
          )}
        </Container>
      </main>
    </div>
  );
};
