import { useEffect, useState } from "react";
import { Player } from "../Player/Player";
import { FindCommentsByLessonService } from "../../services/api/FindCommentsByLessonService";
import { IComment } from "../../types/IComment";
import { useParams } from "react-router-dom";
import { ClassroomCommentCreate } from "../ClassroomComments/ClassroomCommentCreate/ClassroomCommentCreate";
import { useClassRoom } from "../../hooks/ClassRoom";
import Button from "../ui/button/Button";
import { CommentsProvider } from "../../contexts/CommentsContext";
import { ClassroomCommentsStore } from "../ClassroomComments/ClassroomCommentsStore/ClassroomCommentsStore";
import { WatchedService } from "../../services/api/WatchedService";
import Spinner from "../../components/ui/Spinner/Spinner";
import { useTranslation } from 'react-i18next';

export const ClassroomContent = () => {
  const { lesson_id } = useParams();
  const [comments, setComments] = useState<IComment[]>();
  const { lesson, setLesson } = useClassRoom();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    lesson_id && FindCommentsByLessonService.execute(parseInt(lesson_id)).then((data) => {
      setComments(data as IComment[]);
    });
  }, [lesson_id]);

  const handleWatched = () => {
    setLoading(true);
    WatchedService.execute(lesson.id).then((response) => {
      setLesson({...lesson, users_lessons: [{id: response.id, has_watched: response.has_watched}]})
    }).finally(() => setLoading(false));
  }

  return (
    <div className="px-0 md:px-4 lg:pl-96">
      <div className="mx-auto max-w-5xl space-y-14">
        <div className="space-y-4">
          <Player />
          <div className="flex flex-col md:flex-row gap-6 items-center justify-between px-6 py-2 border-b border-gray-200">
            <h2 className="text-lg md:text-2xl text-center md:text-left">{lesson.title}</h2>
            <Button onClick={() => handleWatched()} variant={lesson.users_lessons.length && lesson.users_lessons[0].has_watched === true  ? 'secondary' : 'primary'} className="w-full md:w-auto">
              {loading && <Spinner size="sm" variant={lesson.users_lessons.length && lesson.users_lessons[0].has_watched === true  ? 'primary' : 'secondary'} />}
              <span>{lesson.users_lessons.length && lesson.users_lessons[0].has_watched === true  ? t('pages.Lesson.notCompleted') : t('pages.Lesson.concluded')}</span>
            </Button>
          </div>
          {comments && (
            <div className="px-2 md:px-6">
              <h3 className="text-2xl">{t('pages.Lesson.comments')}</h3>
              <CommentsProvider value={comments}>
                <ClassroomCommentsStore />
                <ClassroomCommentCreate />
              </CommentsProvider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
