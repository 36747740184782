import React, { createContext, useCallback, useState } from "react";
import { ApiInstance } from "../services/api/ApiInstances";
import { IUser } from "../types/IUser";

interface IAuthState {
  token: string;
  user: IUser;
}

export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface IAuthContextData {
  user: IUser;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
}

export interface IAuthProvider {
  children: React.ReactNode;
}

export const AuthContext = createContext<IAuthContextData>(
  {} as IAuthContextData
);

export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem("Academy: token");
    const user = localStorage.getItem("Academy: user");

    if (token && user) {
      ApiInstance.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }
    return {} as IAuthState;
  });

  const signIn = useCallback(
    async ({ email, password }: ISignInCredentials) => {
      const response = await ApiInstance.post("/sessions", {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem("Academy: token", token);
      localStorage.setItem("Academy: user", JSON.stringify(user));

      ApiInstance.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user });
    },
    []
  );

  const signOut = useCallback(() => {
    localStorage.removeItem("Academy: token");
    localStorage.removeItem("Academy: user");

    setData({} as IAuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
