import { useState } from "react";
import Spinner from "../ui/Spinner/Spinner";

export interface IframeLoadingProps {
  src?: string;
  isLoading: boolean;
}

const IframeLoading: React.FC<IframeLoadingProps> = ({ src, isLoading }) => {
  const [loading, setLoading] = useState(isLoading);
  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className="relative">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black">
          <Spinner size="lg" variant="secondary" />
        </div>
      )}
      <iframe
        id={`panda-${src}`}
        src={`https://player-vz-f98c16a1-b5a.tv.pandavideo.com.br/embed/?v=${src}`}
        onLoad={handleLoad}
        className={`aspect-video w-full border-none ${
          loading ? "opacity-0" : "opacity-100"
        } `}
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
        allowFullScreen={true}
      />
    </div>
  );
};

export default IframeLoading;
