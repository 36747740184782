import React, { createContext, useState } from "react";
import { IComment } from "../types/IComment";

export interface ICommentsProviderValue {
  comments: IComment[];
  setComments: (comments: IComment[]) => void;
}

export interface ICommentsProvider {
  value?: IComment[];
  children: React.ReactNode;
}

export const CommentsContext = createContext<ICommentsProviderValue>(
  {} as ICommentsProviderValue
);

export const CommentsProvider: React.FC<ICommentsProvider> = ({
  children,
  value,
}) => {
  const [comments, setComments] = useState<IComment[]>(
    value as IComment[]
  );

  return (
    <CommentsContext.Provider
      value={{
        comments,
        setComments,
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
};
