import { IComment } from "../types/IComment";

/**
 * Note que esta implementação assume que os comentários estão em ordem hierárquica, 
 * ou seja, os comentários pai aparecem antes de seus filhos no array. 
 * Se a ordem não for garantida, você pode precisar classificar 
 * os comentários antes de chamar a função.
 */
export const  BuildCommentTreeAsync = (comments: IComment[]) => {
  return new Promise((resolve, reject) => {
    try {
      const buildTree = (comments: IComment[], parentId: number | null = null) => {
        const childComments = comments.filter(comment => comment.reply_to_id === parentId);

        if (childComments.length === 0) {
          return null;
        }

        return childComments.map(comment => {
          const children = buildTree(comments, comment.id);
          if (children) {
            comment.replies = children;
          }
          return comment;
        });
      };

      const commentTree = buildTree(comments);
      resolve(commentTree);
    } catch (error) {
      reject(error);
    }
  });
}
