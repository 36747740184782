import { IRegisterServiceService } from "../../types/IRegisterServiceService";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  post: (url: string, body: IRegisterServiceService) =>
    ApiInstance.post(url, body).then(ResponseBody),
};

export const RegisterService = {
  execute: (request: IRegisterServiceService): Promise<void> =>
  RequestService.post('/register', request)
};