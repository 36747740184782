import React from "react";
import { cn } from "../../../utils";

const Container = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'lg:px-16" mx-auto max-w-screen-xl px-4 pb-4 md:px-8 md:pb-8 flex-1',
      className
    )}
    {...props}
  />
));

export default Container;
