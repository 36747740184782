import { IApprovalCommentService } from "../../types/IApprovalCommentService";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  put: (url: string, body: IApprovalCommentService) => ApiInstance.put(url, body).then(ResponseBody),
};

export const ApprovalCommentService = {
  execute: (id: number,  body: IApprovalCommentService) => RequestService.put(`/approval/${id}`, body),
};
