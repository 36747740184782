import React from "react";
import { tv, VariantProps } from "tailwind-variants";
import { cn } from "../../../utils/index";

const spinnerVariants = tv({
  base: "animate-spin rounded-full ease-linear",
  variants: {
    variant: {
      primary:
        "border-t-transparent border-l-teal-400 border-r-teal-400 border-b-teal-400",
      secondary:
        "border-t-transparent border-l-white border-r-white border-b-white",
    },
    size: {
      sm: "h-4 w-4 border-2",
      md: "h-8 w-8 border-4",
      lg: "h-16 w-16 border-4",
      xl: "h-32 w-32 border-8",
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});

export interface ButtonProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof spinnerVariants> {}

const Button = React.forwardRef<HTMLDivElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(spinnerVariants({ variant, size }), className)}
      role="status"
      {...props}
    />
  )
);

export default Button;
