import * as React from "react";
import * as ToastPrimitives from "@radix-ui/react-toast";
import ToastViewport from "./ToastViewport";
import Toast from "./Toast";
import ToastTitle from "./ToastTitle";
import ToastDescription from "./ToastDescription";
import ToastClose from "./ToastClose";
import ToastAction from "./ToastAction";

const ToastProvider = ToastPrimitives.Provider;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
};
