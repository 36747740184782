import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const Whatsapp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex bg-teal-500 p-2 text-sm text-white sm:items-center sm:justify-center sm:text-center md:h-10">
      <FaWhatsapp className="mr-4 h-5 w-5 shrink-0" />
      <div className="flex flex-col sm:flex-row sm:items-center">
        {' '}
        {t('commons.whatsapp.title')} &nbsp;{' '}
        <a
          href={t('commons.whatsapp.link')}
          className="font-medium underline hover:no-underline"
        >
          {t('commons.whatsapp.label')}
        </a>
      </div>
    </div>
  );
};

export default Whatsapp;