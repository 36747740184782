import { Header } from "../components/Header/Header"
import Box from "../components/ui/box/Box"
import Container from "../components/ui/container/Container"
import { IoIosNotificationsOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { FindByUserNotificationsService } from "../services/api/FindByUserNotificationsService";
import { INotification } from "../types/INotification";
import { useEffect, useState } from "react";
import Text from "../components/ui/text/Text";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

export const Notifications = () => {
  const [notifications, setNotifications] = useState<INotification[]>();
  const { t } = useTranslation();

  useEffect(() => {
    FindByUserNotificationsService.execute().then((data) => {
      setNotifications(data);
   }).catch(() => {
    toast.error(t('commons.toast.error'));
   })
   }, []);

  return (
    <div>
    <Header />
    <main>
      <Container>
        <div className="py-4 mt-4">
          <h2 className="font-bold text-2xl">{t('pages.Notifications.title')}</h2>
        </div>
        
      {notifications?.length ? (
       <div className="flex flex-col gap-2">
       {notifications.map((notification) => (
       <Box key={notification.id}>
           <div className="flex gap-6">
             <div>
               <IoIosNotificationsOutline className="w-8 h-8 text-teal-500" />
             </div>
             <div>
               <Link to={notification.url} className="hover:text-teal-500">
                 {notification.content}
               </Link>
             </div>
           </div>
         </Box>))}
      </div>
      ) : (
        <div>
        <Box>
          <div className="py-20 flex gap-2 flex-col items-center">
              <IoIosNotificationsOutline className="w-24 h-24 text-teal-500" />
            <h3 className="font-bold text-xl">{t('pages.Notifications.empty.title')}</h3>
            <Text className="text-center">
            {t('pages.Notifications.empty.description')}
            </Text>
          </div>
          </Box>
       </div>
      )}
      </Container>
    </main>
  </div>
  )
}