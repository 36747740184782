import { useState } from 'react';
import { parseISO } from 'date-fns'; 
import { format } from 'date-fns-tz';
import { FaCircleCheck } from "react-icons/fa6";
import Spinner from "../ui/Spinner/Spinner";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IFindAllMeetingsResponse } from "../../types/IFindAllMeetingsResponse";
import Button from '../ui/button/Button';
import { RegisterParticipantService } from '../../services/api/RegisterParticipantService';
import { useTranslation } from 'react-i18next';

export const MeetingScheduled: React.FC<IFindAllMeetingsResponse> = ({
  id,
  title,
  status,
  meeting_date,
  participants,
  participants_limit,
  participants_count
}) => {
const [notification, setNotification] = useState<{type: string, message: string}>();
const [loading, setLoading] = useState<boolean>(false);
const [meeting, setMeeting] =  useState<IFindAllMeetingsResponse>({
  id,
  title,
  status,
  meeting_date,
  participants,
  participants_limit,
  participants_count
})

const { t } = useTranslation();

const handleOnSubmit = () => {
  setLoading(true);
  RegisterParticipantService.execute({meeting_id: id}).then((response) => {
    setMeeting(response.meeting)
    setNotification(response.notification)
  }).finally(() => {
    setLoading(false);
  })
}

  return (
   <div>
    {notification && 
      <div className={`py-2 ${notification.type === 'success' ? 'text-teal-500' : 'text-red-700' }`}>
        {notification.message}
      </div>
    }
     <div className="flex flex-col md:flex-row justify-between items-center gap-6 py-2">
      <div className="flex flex-col md:flex-row md:items-center gap-4">
        <div className='flex items-center gap-2'>
          <FaRegCalendarAlt className="text-teal-500" />
          <span className="bg-teal-100 text-teal-800 px-4 py-1 rounded-full">
          {format(parseISO(`${meeting_date}`), 'dd/MM/yyyy', {timeZone: 'America/Sao_Paulo'})}
          </span>
        </div>
        <h4 className="font-medium">{title}</h4>
      </div>
      {meeting.participants && meeting.participants.length ? (
        <div className='flex gap-2 items-center'>
          <FaCircleCheck className="text-teal-500" />
          <span className="text-teal-500">{t('pages.Meetings.scheduled.registered')}</span>
        </div>
      ) : (
        <>
        { meeting.participants_count < meeting.participants_limit
          ? <Button size='sm' onClick={() => handleOnSubmit()} disabled={loading} className='w-full md:w-auto'>
          {loading && <Spinner size="sm" variant="secondary" />}
          {loading ? <span>{t('pages.Meetings.scheduled.loading')}</span> : <span>{t('pages.Meetings.scheduled.enrollment')}</span>}
         </Button>  
         : <span className='text-red-700'>{t('pages.Meetings.scheduled.unavailable')}</span>
        }
        </>
      )}
    </div>
   </div>
  )
}