import { IComment } from "../../types/IComment";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const RequestService = {
  get: (url: string) =>
  ApiInstance.get<IComment[]>(url).then(ResponseBody),
};

export const FindCommentsByLessonService = {
  execute: (lesson_id: number): Promise<IComment[]> =>
  RequestService.get(`/comments/${lesson_id}/lessons`)
};
