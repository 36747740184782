import { IProfile } from "../../types/IProfile";
import { IUpdateProfile } from "../../types/IUpdateProfile";
import { ApiInstance } from "./ApiInstances";
import { ResponseBody } from "./ResponseBody";

const ProfileRequests = {
  get: (url: string) => ApiInstance.get<IProfile>(url).then(ResponseBody),
  put: (url: string, body: IUpdateProfile) =>
    ApiInstance.put<IUpdateProfile>(url, body).then(ResponseBody),
  //patch: (url: string, body: IUpdateAvatar) => instanceApi.patch<IUpdateAvatar>(url, body, ).then(responseBody),
};

export const profile = {
  getProfile: (): Promise<IProfile> => ProfileRequests.get("/profile"),
  updateProfile: (profile: IUpdateProfile): Promise<IUpdateProfile> =>
    ProfileRequests.put("/profile", profile),
  //updateAvatar: (avatar: IUpdateAvatar): Promise<IProfile> => ProfileRequests.put('/users/avatar', avatar),
};
