import React from "react";
import { cn } from "../../../utils";

const ListRoot = React.forwardRef<
  HTMLUListElement,
  React.HTMLAttributes<HTMLUListElement>
>(({ className, ...props }, ref) => (
  <ul role="list" ref={ref} className={cn("w-full", className)} {...props} />
));

export default ListRoot;
