import { LuPlayCircle } from "react-icons/lu";
import { ILesson } from "../../../types/ILesson";
import { useClassRoom } from "../../../hooks/ClassRoom";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface ClassroomNavigatePrrops {
  toLesson: ILesson;
}

export const ClassroomNavigate: React.FC<ClassroomNavigatePrrops> = ({
  toLesson,
}) => {
  const { lesson, setLesson } = useClassRoom();
  const {course_id } = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const  handleNavigate = (lesson: ILesson) => {
    setLesson(lesson);
    navigate(`/${locale}/lesson/${course_id}/${lesson.id}`);
  }

  return (
    <>
      <div>
        <div className={`flex h-8 w-8 items-center justify-center rounded-full ${toLesson.users_lessons.length && toLesson.users_lessons[0].has_watched === true ? 'bg-green-300' : 'bg-gray-100'}`}>
          <LuPlayCircle className={`h-6 w-6 ${toLesson.users_lessons.length && toLesson.users_lessons[0].has_watched === true ? 'text-green-600' : 'text-gray-500' }`} />
        </div>
      </div>
      <button
        className={`text-left ${
          lesson.id === toLesson.id && " text-green-600 "
        } hover:text-green-600`}
        onClick={() => handleNavigate(toLesson)}
      >
        {toLesson.title}
      </button>
    </>
  );
};
